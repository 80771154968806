import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import http from '../../utils/helpers'
import { Col, Row, Input, Empty } from 'antd'
import Loader from '../loader'
import Error from '../error'
import { useTranslation } from 'react-i18next'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

const MineralOgitOmboriShtabTarkib = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [search, setSearch] = useState("")

    const filter = useMemo(() => {
        return {
            search
        }
    }, [search])

    const { data, isLoading, isError } = useQuery({
        queryKey: ['agro-files', filter],
        queryFn: async () => {
            return await http.get(`agro/files/`, {
                params: filter
            })
        },
        placeholderData: keepPreviousData
    })

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{t('warehouse')}</h2>
            </div>

            <div className='text-right mb-5 flex justify-between items-center'>
                <span>{t('total')} {data?.count} {t('ta')}</span>
                <Input
                    placeholder={t('search')}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    className='w-[250px] text-left'
                />
            </div>

            {
                isLoading ? <Loader /> : (
                    <Row gutter={[24, 24]}>
                        {
                            data?.results?.map((item, index) => {
                                return (
                                    <Col xl={6} lg={8} md={8} sm={12} xs={12} key={index}>
                                        <a href={`${item?.file}`.replace('http', 'https')} target='_blank' rel="noreferrer">
                                            <div className='cursor-pointer'>
                                                <div className='custom-card p-4 h-[100%] flex items-center flex-col justify-center' style={{ minHeight: '150px' }}>
                                                    <img src={item?.logo} alt={item?.title} className='w-[70px] mb-2' />
                                                    <h3 className='m-0 text-[#147461]'>{item?.title}</h3>
                                                </div>
                                            </div>
                                        </a>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                )
            }

            {isError ? <Error /> : null}

            {
                data?.results?.length === 0 ? <Empty /> : null
            }
        </div>
    )
}

export default MineralOgitOmboriShtabTarkib