import { keepPreviousData, useQuery } from '@tanstack/react-query'
import React, { useMemo, useState } from 'react'
import http from '../../../utils/helpers'
import { useTranslation } from 'react-i18next'
import { makeOptions } from '../../../utils'
import { Col, Empty, Pagination, Row, Select } from 'antd'
import Loader from '../../loader'
import Error from '../../error'

const ExporterCompanies = ({ exporterId }) => {
    const { t } = useTranslation()

    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [regionId, setRegionId] = useState(null)

    const filter = useMemo(() => {
        return {
            page: currentPage,
            page_size: pageSize,
            region: regionId,
            exporter: exporterId
        }
    }, [currentPage, pageSize, regionId, exporterId])

    const { data, isLoading, isError } = useQuery({
        queryKey: ['export-registered-company-list', filter],
        queryFn: async () => {
            return await http.get(`/export/registered/company/list/`, {
                params: filter
            })
        },
        placeholderData: keepPreviousData,
    })

    const { data: regions, isLoading: regionsLoading } = useQuery({
        queryKey: ['regions'],
        queryFn: async () => {
            return await http.get(`/common/regions/`)
        }
    })

    return (
        <div className='' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='flex justify-between items-center mb-2 xl:flex-row lg:flex-row md:flex-row flex-col'>
                <span className='mb-2'>{t('total')} {data?.count} {t('ta')}</span>
                <div className='filter-wrap mb-2'>
                    <Select
                        placeholder={t('category')}
                        loading={regionsLoading}
                        value={regionId}
                        options={makeOptions(regions, 'title')}
                        onChange={(e) => setRegionId(e)}
                        allowClear
                    />
                </div>
            </div>

            {
                isLoading ? <Loader /> : (
                    <>
                        <Row gutter={[24, 24]}>
                            {
                                data?.results?.map((item, index) => {
                                    return (
                                        <Col xl={6} lg={8} md={12} sm={24} xs={24} key={index}>
                                            <div className='custom-card p-4 h-[100%] flex flex-col'>
                                                <p className=''>{t('company_name')} <b>{item?.company_name}</b></p>
                                                <p className='text-[#147461]'>{t('address')} <b>{item?.address}</b></p>
                                                <p className='text-[#147461]'>{t('registered_number_in_uz')} <b>{item?.registered_number}</b></p>
                                                <p className='text-[#147461]'>{t('email_or_website')} <b>{item?.email_or_website}</b></p>
                                                <p className='text-[#147461]'>{t('company_owner')} <b>{item?.company_owner}</b></p>
                                                <p className='text-[#147461] m-0'>{t('company_phone')} <b><a href={`tel:${item?.phone_number}`}>{item?.phone_number}</a></b></p>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>

                        {
                            data?.results?.length === 0 ? <Empty /> : null
                        }

                        {
                            Array.isArray(data?.results) ? (
                                <div className='text-right mt-5'>
                                    <Pagination
                                        defaultCurrent={currentPage}
                                        current={currentPage}
                                        pageSize={pageSize}
                                        showSizeChanger={true}
                                        pageSizeOptions={[20, 50, 100]}
                                        onChange={(current, size) => {
                                            setCurrentPage(current)
                                            setPageSize(size)
                                        }}
                                        total={data?.count}
                                    />
                                </div>
                            ) : null
                        }
                    </>
                )
            }

            {isError ? <Error /> : null}
        </div>
    )
}

export default ExporterCompanies