import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie"
import { writeHeaders } from "../utils/helpers";
import { writeNarxHeaders } from "../utils/helpers/narx-http";

const accessToken = Cookies.get("access_token") ?? null
const narxAccessToken = Cookies.get("narx_access_token") ?? null

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        accessToken,
        narxAccessToken,
        isAuth: !!accessToken
    },
    reducers: {
        userLogin: (state, action) => {
            state.accessToken = action.payload.token
            state.isAuth = true
            Cookies.set("access_token", action.payload.token, { expires: 30 })
            writeHeaders(action.payload.token)
        },
        narxUserLogin: (state, action) => {
            state.narxAccessToken = action.payload.token
            Cookies.set("narx_access_token", action.payload.token, { expires: 30 })
            writeNarxHeaders(action.payload.token)
        },
        userLogout: (state) => {
            state.accessToken = null
            state.isAuth = false
            Cookies.remove("access_token")
            Cookies.remove("narx_access_token")
        }
    }
})

export const { userLogin, userLogout, narxUserLogin } = authSlice.actions
export default authSlice.reducer