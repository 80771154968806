import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import http from '../../utils/helpers'
import { Col, Row } from 'antd'
import { useQuery } from '@tanstack/react-query'
import Loader from '../loader'
import Error from '../error'

const FieldEdge = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { data, isLoading, isError } = useQuery({
        queryKey: ['get-reestr-data-file1'],
        queryFn: async () => {
            return await http.get('common/useful_files_group/dala-cheti/')
        }
    })

    if (isLoading) {
        return <Loader />
    } else if (isError) {
        return <Error />
    } else
        return (
            <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
                <div className='mb-5 flex align-center'>
                    <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                    <h2 className='m-0'>{t('field_age')}</h2>
                </div>

                <div className='text-right mb-5 flex justify-between items-center'>
                    <span>{t('total')} {data?.files?.length} {t('ta')}</span>
                </div>

                <Row gutter={[24, 24]}>
                    {
                        data?.files?.map((item, index) => {
                            return (
                                <Col xl={4} lg={6} md={8} sm={8} xs={12} key={index}>
                                    <Link to={item.file} target='_blank' rel='noreferrer'>
                                        <div className='cursor-pointer'>
                                            <div className='custom-card p-4 flex items-center flex-col'>
                                                {/* <div
                                                    className='w-[60px] h-[70px] mx-auto mb-3'
                                                    style={{
                                                        backgroundImage: `url(${item?.logo?.replace("http:", "https:")})`,
                                                        backgroundPosition: "center right",
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundSize: "stretch",
                                                        maskImage: `url(${item?.logo?.replace("http:", "https:")})`,
                                                        backgroundColor: "green" ?? 'black',
                                                        maskRepeat: 'no-repeat',
                                                        maskPosition: 'center',
                                                        maskSize: 'contain'
                                                    }}
                                                ></div> */}
                                                <img src={item?.logo} alt='' className='w-16 h-16 mb-3' />
                                                <div className='h-10'>
                                                    <h3 className='m-0 text-[#147461]'>{item?.title}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        )
}

export default FieldEdge