import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL_NARX } from "../constants";

const initialLang = Cookies.get('i18next')

const token = Cookies.get("narx_access_token")

const narxHttp = axios.create({
    baseURL: BASE_URL_NARX,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Accept-Language': initialLang,
    }
})

narxHttp.interceptors.request.use(
    config => {
        if (token) {
            config.headers = {
                'Authorization': `Token ${token}`,
                'Accept-Language': initialLang,
            }
            return config
        } else {
            return config
        }
    }
)

narxHttp.interceptors.response.use(
    response => response.data,
    error => {
        if (error?.response?.status === 401) {
            // toast.warning('Token has expired, Please login!')
            Cookies.remove("narx_access_token")
            window.location.href = "/abroad-prices"
        }
        return Promise.reject(error)
    }
)

export function writeNarxHeaders(t) {
    narxHttp.defaults.headers.common['Authorization'] = `Token ${t}`
}

export default narxHttp