import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Row, Input, Pagination, Select, Empty, Divider } from 'antd'
import Loader from '../loader'
import Error from '../error'
import { makeOptions } from '../../utils'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'

const Minerals = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [search, setSearch] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [biocideId, setBiocideId] = useState(null)

    const filter = useMemo(() => {
        return {
            key_word: search,
            offset: (currentPage - 1) * pageSize,
            limit: pageSize,
            biocide: biocideId
        }
    }, [search, currentPage, pageSize, biocideId])


    const { data, isLoading, isError } = useQuery({
        queryKey: ['agro-minerals', filter],
        queryFn: async () => {
            return await axios.get(`https://reg.efito.uz/api/v1/efito/certificates/`, {
                params: filter,
            })
        },
        // placeholderData: keepPreviousData
    })

    const { data: categories, isLoading: categoriesLoading } = useQuery({
        queryKey: ['biocides'],
        queryFn: async () => {
            return await axios.get(`https://reg.efito.uz/api/v1/product/types`)
        }
    })

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{t('authorized_protective_equipment')}</h2>
            </div>

            <div className='flex justify-between items-center mb-2 xl:flex-row lg:flex-row md:flex-row flex-col'>
                <span className='mb-2'>{t('total')} {data?.data?.count} {t('ta')}</span>
                <div className='filter-wrap mb-2'>
                    <Input
                        placeholder={t('search')}
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                    <Select
                        placeholder={t('categories')}
                        loading={categoriesLoading}
                        value={biocideId}
                        options={makeOptions(categories?.data?.data, 'type', 'code')}
                        onChange={(e) => setBiocideId(e)}
                        allowClear
                    />
                </div>
            </div>

            {
                isLoading ? <Loader /> : (
                    <>
                        <Row gutter={[24, 24]}>
                            {
                                data?.data?.results?.map((item, index) => {
                                    return (
                                        <Col xl={6} lg={8} md={8} sm={12} xs={24} key={index}>
                                            <div className='custom-card p-4 flex flex-col'>
                                                <p>{t('name')} <b>{item?.biocide_trade_name} {item.concentration}</b></p>
                                                <p>{t('categories')} <b>{item?.biocide.name_uz}</b></p>
                                                <p>{t('phone')} <b><a href={`tel:${item?.applicant_phone}`}>{item?.applicant_phone}</a></b></p>
                                                <p>{t('active_substance')} <b>{item?.irritant_substance}</b></p>
                                                <p>{t('how_many_times_per_season')} <strong>{item.scopes[0]?.time}</strong></p>
                                                <p>{t('during_which')} <strong>{item.scopes[0]?.scope_uz}</strong></p>
                                                <p>{t('validity_period')} <strong>{item.expire_date}</strong></p>
                                                <p>{t('enterprise')} <strong>{item.applicant_name}</strong></p>
                                                {
                                                    item.scopes.map(((s, i) => {
                                                        return (
                                                            <div className='ml-[20px]' key={i}>
                                                                <p>{t('crop_type')} <strong>{s.plant_type_uz}</strong></p>
                                                                <p>{t('consumption_rate')} <strong>{s.amount} {s.unit_name}</strong></p>
                                                                <p className='m-0'>{t('against_which_harmful_organism')} <strong>{s.harmful_organisms_uz}</strong></p>
                                                                <Divider />
                                                            </div>
                                                        )
                                                    }))
                                                }
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>

                        {
                            data?.data?.results?.length === 0 ? <Empty /> : null
                        }

                        {
                            Array.isArray(data?.data?.results) ? (
                                <div className='text-right mt-5'>
                                    <Pagination
                                        defaultCurrent={currentPage}
                                        current={currentPage}
                                        pageSize={pageSize}
                                        showSizeChanger={true}
                                        pageSizeOptions={[20, 50, 100]}
                                        onChange={(current, size) => {
                                            setCurrentPage(current)
                                            setPageSize(size)
                                        }}
                                        total={data?.data?.count}
                                    />
                                </div>
                            ) : null
                        }
                    </>
                )
            }

            {isError ? <Error /> : null}
        </div>
    )
}

export default Minerals