import React, { useMemo, useState } from 'react'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import http from '../../utils/helpers'
import { Col, Row, Input, Select, Empty } from 'antd'
import Loader from '../loader'
import Error from '../error'
import { makeOptions } from '../../utils'
import { useTranslation } from 'react-i18next'

const TuproqTahlilLab = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [search, setSearch] = useState("")
    const [regionId, setRegionId] = useState(null)

    const filter = useMemo(() => {
        return {
            search,
            region: regionId
        }
    }, [search, regionId])

    const { data, isLoading, isError } = useQuery({
        queryKey: ['agro-tahlil', filter],
        queryFn: async () => {
            return await http.get(`agro/tahlil/`, {
                params: filter
            })
        },
        placeholderData: keepPreviousData
    })

    const { data: regions, isLoading: regionsLoading } = useQuery({
        queryKey: ['regions'],
        queryFn: async () => {
            return await http.get(`/common/regions/`)
        }
    })

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{t('soil_analysis_laboratories')}</h2>
            </div>

            <div className='flex justify-between items-center mb-2 xl:flex-row lg:flex-row md:flex-row flex-col'>
                <span className='mb-2'>{t('total')} {data?.length} {t('ta')}</span>
                <div className='filter-wrap mb-2'>
                    <Input
                        placeholder={t('search')}
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                    <Select
                        placeholder={t('category')}
                        loading={regionsLoading}
                        value={regionId}
                        options={makeOptions(regions, 'title')}
                        onChange={(e) => setRegionId(e)}
                        allowClear
                    />
                </div>
            </div>

            {
                isLoading ? <Loader /> : (
                    <Row gutter={[24, 24]}>
                        {
                            data?.map((item, index) => {
                                return (
                                    <Col xl={6} lg={8} md={8} sm={12} xs={24} key={index}>
                                        <div className='custom-card p-4 h-[100%] flex flex-col'>
                                            <h4 className='text-[16px] text-center font-bold'>{item?.region?.title}</h4>
                                            <p className='text-center'>{item?.title}</p>
                                            <p>{t('leader')} <b>{item?.owner}</b></p>
                                            <p>{t('phone')} <b><a href={`tel:${item?.phone}`}>{item?.phone}</a></b></p>
                                            <p className='m-0'>{t('address')} <b>{item?.address}</b></p>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                )
            }

            {isError ? <Error /> : null}
            {
                data?.length === 0 ? <Empty /> : null
            }
        </div>
    )
}

export default TuproqTahlilLab