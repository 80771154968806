import React, { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Col, Empty, Input, Pagination, Row } from 'antd'
import Loader from '../loader'
import Error from '../error'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import http from '../../utils/helpers'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

const InnerForeignBrands = () => {
    const navigate = useNavigate()
    const params = useParams()
    const { t } = useTranslation()
    const { title } = queryString.parse(window.location.search)

    const [search, setSearch] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)

    const filter = useMemo(() => {
        return {
            search,
            page: search.length > 0 ? 1 : currentPage,
            page_size: pageSize,
            country: params.countryId,
        }
    }, [search, currentPage, pageSize, params])

    const { data, isLoading, isError } = useQuery({
        queryKey: ['export-brand-company', filter],
        queryFn: async () => {
            return await http.get(`/export/brand/company/`, {
                params: filter,
            })
        },
        placeholderData: keepPreviousData
    })

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{title}</h2>
            </div>

            <div className='text-right mb-5 flex justify-between items-center'>
                <span className='mb-2'>{t('total')} {data?.count} {t('ta')}</span>
                <Input
                    placeholder={t('search')}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    className='w-[250px] text-left'
                />
            </div>

            {
                isLoading ? <Loader /> : (
                    <>
                        <Row gutter={[24, 24]}>
                            {
                                data?.results?.map((item, index) => {
                                    return (
                                        <Col xl={6} lg={8} md={12} sm={24} xs={24} key={index}>
                                            <div className='custom-card p-4 h-[100%] cursor-pointer'>
                                                <h4 className='font-bold mb-3 text-center'>{item?.name}</h4>
                                                <div className='text-left'>
                                                    <p>{t('manager')} <b>{item?.manager}</b></p>
                                                    <p>{t('phone')}
                                                        {
                                                            item?.phone_numbers?.map((phone, i) => {
                                                                return (
                                                                    <a key={i} className='mx-2' href={`tel:${phone}`}>{phone}</a>
                                                                )
                                                            })
                                                        }
                                                    </p>
                                                    <p>{t('email')}: <a href={`mailto:${item?.email}`}>{item?.email ?? '-'}</a></p>
                                                    <p>{t('activity')}: <b>{item?.type_of_activity}</b></p>
                                                    <p className='m-0'>{t('main_direction')} <b>{item?.main_direction}</b></p>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>

                        {
                            data?.results?.length === 0 ? <Empty /> : null
                        }

                        {
                            Array.isArray(data?.results) ? (
                                <div className='text-right mt-5'>
                                    <Pagination
                                        defaultCurrent={currentPage}
                                        current={currentPage}
                                        pageSize={pageSize}
                                        showSizeChanger={true}
                                        pageSizeOptions={[20, 50, 100]}
                                        onChange={(current, size) => {
                                            setCurrentPage(current)
                                            setPageSize(size)
                                        }}
                                        total={data?.count}
                                    />
                                </div>
                            ) : null
                        }
                    </>
                )
            }
            {isError ? <Error /> : null}
        </div>
    )
}

export default InnerForeignBrands