import { Divider, Row } from 'antd'
import React from 'react'
import Card from '../../components/card'
import biolabImage from "../../assets/images/biolab.png"
import osimlilKlinikaImage from "../../assets/images/osimlik_klinika.png"
import mineralOgitImage from "../../assets/images/warehouse.png"
import sesLaboratyImage from "../../assets/images/ses_labaratory.png"
import tuproqTahlilLaboratoriyaImage from "../../assets/images/tuproq_tahlil.png"
import feromonTutqichImage from "../../assets/images/feromon.png"
import fieldEdgeImage from "../../assets/images/field_edge.png"
import isoImage from "../../assets/images/iso.png"
import certificationImage from "../../assets/images/certification.png"
import { useTranslation } from 'react-i18next'

const ServicesTypes = () => {
    const { t } = useTranslation()

    const xizmatTurlari = [
        {
            id: null,
            title: t('list_of_biolaboratories'),
            image: biolabImage,
            color: 'green',
            link: 'biolabs'
        },
        {
            id: null,
            title: t('iso'),
            image: isoImage,
            color: 'green',
            link: 'iso'
        },
        {
            id: null,
            title: t('certification'),
            image: certificationImage,
            color: 'green',
            link: 'certification'
        },
        {
            id: null,
            title: t('herbal_clinics'),
            image: osimlilKlinikaImage,
            color: 'green',
            link: 'clinics'
        },
        {
            id: null,
            title: t('ses_laboratory'),
            image: sesLaboratyImage,
            color: 'green',
            link: 'ses-laboratory'
        },
        {
            id: null,
            title: t('warehouse'),
            image: mineralOgitImage,
            color: 'green',
            link: 'mineral-ogit-ombor'
        },
        {
            id: null,
            title: t('soil_analysis_laboratories'),
            image: tuproqTahlilLaboratoriyaImage,
            color: 'green',
            link: 'tuproq-tahlil-lab'
        },
        {
            id: null,
            title: t('feromon'),
            image: feromonTutqichImage,
            color: 'green',
            link: 'feromon-tutqich'
        },
        {
            id: null,
            title: t('field_age'),
            image: fieldEdgeImage,
            color: 'green',
            link: 'field-edge',
            // pending: true
        },
    ]

    return (
        <>
            <h1 className='mb-5'>{t('services_types')}</h1>
            <Row
                gutter={[24, 24]}
            >
                {
                    xizmatTurlari?.map((item, index) => {
                        return <Card key={index} item={item} />
                    })
                }
            </Row>
            <br />
            <Divider />
        </>
    )
}

export default ServicesTypes