import { Col, Row } from 'antd'
import queryString from 'query-string'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import importSeed from '../../assets/images/import_sapling.png'
import localSeed from '../../assets/images/local_sapling.png'
import recomandation from '../../assets/images/recommendation.png'
import http from '../../utils/helpers'
import { useQuery } from '@tanstack/react-query'

const Kochat = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { data: kuchatData } = useQuery({
        queryKey: ['get-kuchat-tavsiya-file'],
        queryFn: async () => {
            return await http.get('common/useful_files/kuchat_tavisya/')
        }
    })

    const items = useMemo(() => [
        {
            type: "local_sapling",
            title: t('local_spling'),
            image: localSeed,
            link: ''
        },
        {
            type: "import_saplings",
            title: t('import_spling'),
            image: importSeed,
            link: ''
        },
        {
            type: "download",
            title: t('get_spling_file'),
            image: recomandation,
            link: kuchatData?.file
        },
    ], [t, kuchatData])

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{t('seedlings')}</h2>
            </div>

            <div className='text-right mb-5 flex justify-between items-center'>
                <span>{t('total')} {items?.length} {t('ta')}</span>
            </div>

            <Row gutter={[24, 24]}>
                {
                    items?.map((item, index) => {
                        return (
                            <Col xl={4} lg={6} md={8} sm={8} xs={12} key={index}>
                                {
                                    item?.type === "download" ? (
                                        <a href={item.link} target='_blank' rel='noreferrer'>
                                            <div className='custom-card p-4 h-[100%] flex items-center flex-col'>
                                                <div
                                                    className='w-[60px] h-[70px] mx-auto mb-3'
                                                    style={{ maskImage: `url(${item?.image})`, backgroundColor: "green" ?? 'black', maskRepeat: 'no-repeat', maskPosition: 'center', maskSize: 'contain' }}
                                                ></div>
                                                <h3 className='m-0 text-[#147461]'>{item?.title}</h3>
                                            </div>
                                        </a>
                                    ) : (
                                        <div
                                            className='cursor-pointer'
                                            onClick={() => navigate(`${item?.type}/?${queryString.stringify({ title: item?.title })}`)}
                                        >
                                            <div className='custom-card p-4 h-[100%] flex items-center flex-col'>
                                                <div
                                                    className='w-[60px] h-[70px] mx-auto mb-3'
                                                    style={{ maskImage: `url(${item?.image})`, backgroundColor: "green" ?? 'black', maskRepeat: 'no-repeat', maskPosition: 'center', maskSize: 'contain' }}
                                                ></div>
                                                <h3 className='m-0 text-[#147461]'>{item?.title}</h3>
                                            </div>
                                        </div>
                                    )
                                }
                            </Col>
                        )
                    })
                }
            </Row>
        </div>
    )
}

export default Kochat