import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import http from '../../utils/helpers'
import { Col, Row, Pagination, Select, Empty } from 'antd'
import Loader from '../loader'
import Error from '../error'
import { makeOptions } from '../../utils'
import { useTranslation } from 'react-i18next'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import certifiedImg from "../../assets/images/certified_img.png"

const OrganicCompanies = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    // const [search, setSearch] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [regionId, setRegionId] = useState(null)
    const [certificated, setCertificated] = useState(null)

    const filter = useMemo(() => {
        return {
            // search,
            page: regionId ? 1 : currentPage,
            page_size: pageSize,
            region: regionId,
            is_have_certificate: certificated
        }
    }, [currentPage, pageSize, regionId, certificated])

    const { data, isLoading, isError } = useQuery({
        queryKey: ['export-organic-certificate', filter],
        queryFn: async () => {
            return await http.get(`/export/organic/certificate/`, {
                params: filter
            })
        },
        placeholderData: keepPreviousData
    })

    const { data: regions, isLoading: regionsLoading } = useQuery({
        queryKey: ['organic-cerificate-regions'],
        queryFn: async () => {
            return await http.get(`/export/organic/certificate/regions/`)
        }
    })

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{t('organic_companies')}</h2>
            </div>

            <div className='flex justify-between items-center mb-2 xl:flex-row lg:flex-row md:flex-row flex-col'>
                <span className='mb-2'>{t('total')} {data?.count} {t('ta')}</span>
                <div className='filter-wrap mb-2'>
                    {/* <Input
                        placeholder={t('search')}
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    /> */}
                    <Select
                        placeholder={t('all_of_them')}
                        options={[
                            { label: t('all_of_them'), value: null },
                            { label: t('certificated'), value: true },
                            { label: t('not-certificated'), value: false },
                        ]}
                        value={certificated}
                        onChange={(e) => setCertificated(e)}
                    />
                    <Select
                        placeholder={t('category')}
                        loading={regionsLoading}
                        value={regionId}
                        options={makeOptions(regions, 'name')}
                        onChange={(e) => setRegionId(e)}
                        allowClear
                    />
                </div>
            </div>

            {
                isLoading ? <Loader /> : (
                    <>
                        <Row gutter={[24, 24]}>
                            {
                                data?.results?.map((item, index) => {
                                    return (
                                        <Col xl={6} lg={8} md={12} sm={12} xs={24} key={index}>
                                            <div className='custom-card h-[100%] p-4 flex flex-col relative'>
                                                {item.is_have_certificate && <img src={certifiedImg} alt='' className='lg:w-16 md:w-14 w-12 z-0 absolute right-5 top-12' />}
                                                <h4 className='text-[16px] font-bold text-center'>{item?.company}</h4>
                                                <p>{t('region')}: <b>{item?.region}, {item?.city}</b></p>
                                                <p>{t('phone')} <b><a href={`tel:${item?.phone}`}>{item?.phone}</a></b></p>
                                                <p>{t('leader')} <b>{item?.owner}</b></p>
                                                <p>{t('status')} <b>{item?.is_have_certificate ? t('certificated') : t('not-certificated')}</b></p>
                                                {item?.q_managment_system !== "None" && <p>{t('q_menegement_system')} <b className='text-green-800'>{item.q_managment_system}</b></p>}
                                                {
                                                    item?.product_data?.map((p, i) => (
                                                        <div style={{ borderTop: "2px dashed green" }} className='py-3' key={i}>
                                                            <p>{t('product_name')} <b>{p?.product?.name}</b></p>
                                                            <p className='m-0'>{t('area_of_the_garden')} <b>{p?.area} {t('a_hectare')}</b></p>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>

                        {
                            data?.results?.length === 0 ? <Empty /> : null
                        }

                        {
                            Array.isArray(data?.results) ? (
                                <div className='text-right mt-5'>
                                    <Pagination
                                        defaultCurrent={currentPage}
                                        current={currentPage}
                                        pageSize={pageSize}
                                        showSizeChanger={true}
                                        pageSizeOptions={[20, 50, 100]}
                                        onChange={(current, size) => {
                                            setCurrentPage(current)
                                            setPageSize(size)
                                        }}
                                        total={data?.count}
                                    />
                                </div>
                            ) : null
                        }
                    </>
                )
            }

            {isError ? <Error /> : null}
        </div>
    )
}

export default OrganicCompanies