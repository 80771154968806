import queryString from 'query-string'
import React, { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import http from '../../utils/helpers'
import Loader from '../loader'
import Error from '../error'
import { Segmented } from 'antd'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import ExporterCompanies from './components/exporter-companies'
import ExporterGardens from './components/exporter-gardens'

const InnerExportProduct = () => {
    const params = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { title } = queryString.parse(window.location.search)

    const filter = useMemo(() => {
        return {
            export_products: params.productId,
            export_country: params.countryId
        }
    }, [params])

    const { data, isLoading, isError } = useQuery({
        queryKey: ['export-country-product', filter],
        queryFn: async () => {
            return await http.get(`export/v2/country/list/`, {
                params: filter
            })
        },
        placeholderData: keepPreviousData,
    })

    const [activeTab, setActiveTab] = useState('requirement')

    const options = useMemo(() => {
        const baseOptions = [{ label: t('requirement'), value: 'requirement' }];
        if (data?.[0]?.exporter_companies_exsists) {
            baseOptions.push({ label: t('exporter_companies'), value: 'exporter_companies' });
        }
        if (data?.[0]?.exporter_gardens_exsists) {
            baseOptions.push({ label: t('exporter_gardens'), value: 'exporter_gardens' });
        }
        return baseOptions;
    }, [data, t]);

    const exporterId = useMemo(() => {
        if (data) {
            return data[0]?.id
        } return null
    }, [data])


    if (isLoading) {
        return <Loader />
    }
    if (isError) {
        return <Error />
    }

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-10 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{title}</h2>
            </div>

            <h3 className='mb-5'>{data?.title}</h3>
            <div style={{ overflowX: 'auto' }}>
                <Segmented
                    style={{
                        marginBottom: 16,
                    }}
                    options={options}
                    value={activeTab}
                    onChange={e => setActiveTab(e)}
                />
            </div>
            {
                activeTab === 'requirement' ? (
                    <div>
                        {parse(data[0]?.requirement)}
                    </div>
                ) : null
            }

            {
                activeTab === 'exporter_companies' ? (
                    <ExporterCompanies exporterId={exporterId} />
                ) : null
            }

            {
                activeTab === 'exporter_gardens' ? (
                    <ExporterGardens exporterId={exporterId} />
                ) : null
            }
        </div>
    )
}

export default InnerExportProduct